import React from 'react';
import styled, { css } from 'styled-components/macro';
import faSpinner from 'assets/spiner.gif';
import { ReactComponent as Arrow } from 'assets/img/Quiz/arrow-button.svg';

const Icon = styled.img`
  width: 20px;
  height: 20px;
  @media screen and (min-width: 992px) {
    width: 32px;
    height: 32px;
  }
`;

const ButtonArrow = styled.span`
  display: ${({ noArrow }) => (noArrow ? 'none' : 'inline-block')};
  margin-left: 10px;
  svg {
    transition: fill 0.3s;
    fill: #161616;
    width: 16px;
    height: 16px;
    @media screen and (min-width: 992px) {
      width: 22px;
      height: 22px;
    }
  }
`;

export const Button = styled.button`
  font-family: ${({ theme, fFamily }) => theme.fonts[fFamily]};
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  outline: none;
  border: none;
  position: relative;
  display: block;
  margin: ${({ customMarginMob }) => customMarginMob || '50px auto 0 auto'};
  width: ${({ customWidthMob }) => customWidthMob || '150px'};
  padding: ${({ customPaddingMob }) => customPaddingMob || '15px'};
  border-radius: 25px;
  font-size: ${({ theme, customFsMob }) => customFsMob || theme.fs.h7};

  font-weight: ${({ theme }) => theme.fw.bold};
  color: ${({ theme }) => theme.colors.secondaryText};
    background: linear-gradient(to right, #161616, #161616);
  box-shadow: 2px 2px 12px 0px #161616;
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ButtonArrow} {
    /* color: ${({ disabled, theme }) => disabled && theme.colors.secondray}; */
      svg {
        fill: #E89615;
      }
  }

  &:hover {
    cursor: pointer;
    background-position: 99% 0;
    box-shadow: 0px 0px 15px 0px rgba(46, 45, 65, 0.8);
    transform: scale(1.02);
    background: linear-gradient(to right, #ffb13b, #e89615);

    ${ButtonArrow} {
      svg {
        fill: #161616;
      }
    }
  }
  @media screen and (min-width: 992px) {
    margin: ${({ customMargindesk }) => customMargindesk || '50px auto 0 auto'};
    width: ${({ customWidthDesk }) => customWidthDesk || '250px'};
    font-size: ${({ theme, customFsDesk }) => customFsDesk || theme.fs.h2};
    padding: ${({ customPaddingDesk }) => customPaddingDesk || '14px'};
    border-radius: 35px;
  }

  &:disabled {
    opacity: 0.5;
    transform: scale(1);
    background-position: 99% 0;
    ${({ isDisabled }) =>
      isDisabled === true &&
      css`
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed;
      `}

    &:hover {
      background-size: 300% 100%;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonNext = ({
  history,
  linkTo,
  children,
  type,
  handleClick,
  noArrow,
  customFsMob,
  customFsDesk,
  customWidthMob,
  customWidthDesk,
  customPaddingMob,
  customPaddingDesk,
  textTransform,
  createOrder,
  isLoadingSpinner,
  transformArrowNone,
  customMarginMob,
  customMargindesk,
  isDisabled,
  addOrder,
  productName,
  productId,
  path,
  goToPanel,
  customFw,
  fFamily,
  language,
}) => {
  const handleOnClick = event => {
    if (history && linkTo) {
      history.push(linkTo);
    }

    if (handleClick) {
      handleClick(event);
    }
    if (goToPanel) {
      goToPanel();
    }

    if (createOrder) {
      if (language === 'en') {
        createOrder(history, false, `quiz/order/add-rates`);
      } else {
        createOrder(history, false);
      }
    }
    if (addOrder) {
      addOrder(productName, productId, path, history);
    }
  };

  return (
    <>
      {type === 'submit' ? (
        <Button
          type="submit"
          customFsMob={customFsMob}
          customFsDesk={customFsDesk}
          customWidthMob={customWidthMob}
          customWidthDesk={customWidthDesk}
          customPaddingMob={customPaddingMob}
          customPaddingDesk={customPaddingDesk}
          textTransform={textTransform}
          onClick={event => handleOnClick(event)}
          customMarginMob={customMarginMob}
          customMargindesk={customMargindesk}
          disabled={isLoadingSpinner || isDisabled}
          isDisabled={isDisabled}
          customFw={customFw}
          fFamily={fFamily}
        >
          <TextWrapper>
            {isLoadingSpinner && (
              <>
                <Icon src={faSpinner} alt="" /> &nbsp;
              </>
            )}
            {children}{' '}
            <ButtonArrow noArrow={noArrow}>
              <Arrow />
            </ButtonArrow>
          </TextWrapper>
        </Button>
      ) : (
        <Button
          type="button"
          customFsMob={customFsMob}
          customFsDesk={customFsDesk}
          customWidthMob={customWidthMob}
          customWidthDesk={customWidthDesk}
          customPaddingMob={customPaddingMob}
          customPaddingDesk={customPaddingDesk}
          textTransform={textTransform}
          onClick={event => handleOnClick(event)}
          customMarginMob={customMarginMob}
          customMargindesk={customMargindesk}
          disabled={isLoadingSpinner || isDisabled}
          isDisabled={isDisabled}
          customFw={customFw}
          fFamily={fFamily}
        >
          {isLoadingSpinner && (
            <>
              {' '}
              <img src={faSpinner} alt="" /> &nbsp;
            </>
          )}{' '}
          {children}{' '}
          <ButtonArrow customFw={customFw} noArrow={noArrow}>
            <Arrow />
          </ButtonArrow>
        </Button>
      )}
    </>
  );
};

export default ButtonNext;
